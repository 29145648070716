<template>
  <div>
    <b-card>
      <b-form-group
        label="Тип *"
        label-for="i-type"
        class="mb-0"
      >
        <v-select
          id="i-type-select"
          v-model.trim="type"
          placeholder="Выберите тип"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="types"
          :reduce="i => i.value"
          :clearable="false"
          input-id="i-type"
          label="label"
        />
      </b-form-group>
    </b-card>

    <component :is="formComponent" />
  </div>
</template>

<script>
import {
  BCard, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, computed } from 'vue'
import camelCase from 'lodash/camelCase'
import { types } from '@/composables/useIntegrations'
import YandexMarketFbsForm from '../components/yandex-market-fbs/forms/CreateForm.vue'
import YandexMarketDbsForm from '../components/yandex-market-dbs/forms/CreateForm.vue'
import MerlionFreshYmfbsForm from '../components/merlion-fresh-ymfbs/forms/CreateForm.vue'
import MerlionYmdbsForm from '../components/merlion-ymdbs/forms/CreateForm.vue'

export default {
  name: 'CreateIntegrationView',
  components: {
    BCard,
    BFormGroup,
    vSelect,
    YandexMarketFbsForm,
    YandexMarketDbsForm,
    MerlionFreshYmfbsForm,
    MerlionYmdbsForm,
  },
  setup() {
    const type = ref(types[0].value)

    const formComponent = computed(() => camelCase(`${type.value}_form`))

    return {
      type,
      types,
      formComponent,
    }
  },
  mounted() {
    if (!this.$store.getters['workingMode/mode']) {
      this.$router.push({ name: 'dashboard' })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
