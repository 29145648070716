<template>
  <b-overlay
    :show="loading"

    spinner-variant="primary"
  >
    <b-card>
      <div class="">
        <b-row v-if="error_message">
          <b-col>
            <small
              class="text-danger d-block mb-1"
            >
              {{ error_message }}
            </small>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Название *"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model.trim="item.name"
                placeholder="Введите название"
                :state="get(validation, 'name[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'name[0]')"
                class="text-danger"
              >
                {{ get(validation, 'name[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group
              label="ClientID *"
              label-for="client_id"
            >
              <b-form-input
                id="client_id"
                v-model.trim="item.client_id"
                placeholder="Введите ClientID"
                :state="get(validation, 'client_id[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'client_id[0]')"
                class="text-danger"
              >
                {{ get(validation, 'client_id[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Client secret *"
              label-for="client_secret"
            >
              <b-form-input
                id="client_secret"
                v-model.trim="item.client_secret"
                placeholder="Введите Client secret"
                :state="get(validation, 'client_secret[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'client_secret[0]')"
                class="text-danger"
              >
                {{ get(validation, 'client_secret[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Идентификатор бизнес-аккаунта *"
              label-for="business_id"
            >
              <b-form-input
                id="business_id"
                v-model.trim="item.business_id"
                placeholder="Введите Идентификатор бизнес-аккаунта"
                :state="get(validation, 'business_id[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'business_id[0]')"
                class="text-danger"
              >
                {{ get(validation, 'business_id[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Идентификатор кампании *"
              label-for="campaign"
            >
              <b-form-input
                id="campaign"
                v-model.trim="item.campaign"
                placeholder="Введите Идентификатор кампании"
                :state="get(validation, 'campaign[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'campaign[0]')"
                class="text-danger"
              >
                {{ get(validation, 'campaign[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Авторизационный токен кампании *"
              label-for="campaign_token"
            >
              <b-form-input
                id="campaign_token"
                v-model.trim="item.campaign_token"
                placeholder="Введите Авторизационный токен кампании"
                :state="get(validation, 'campaign_token[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'campaign_token[0]')"
                class="text-danger"
              >
                {{ get(validation, 'campaign_token[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Идентификатор склада *"
              label-for="warehouse_id"
            >
              <b-form-input
                id="warehouse_id"
                v-model.trim="item.warehouse_id"
                placeholder="Введите идентификатор склада"
                :state="get(validation, 'warehouse_id[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'warehouse_id[0]')"
                class="text-danger"
              >
                {{ get(validation, 'warehouse_id[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Включить НДС"
              label-for="include_vat"
            >
              <b-form-checkbox
                id="include_vat"
                v-model="item.include_vat"
                :state="get(validation, 'include_vat[0]') ? false : null"
                switch
                :value="true"
                :unchecked-value="false"
              >
                <template v-if="item.include_vat">
                  Вкл
                </template>
                <template v-else>
                  Выкл
                </template>
              </b-form-checkbox>
              <small
                v-if="!!get(validation, 'include_vat[0]')"
                class="text-danger"
              >
                {{ get(validation, 'include_vat[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Использовать пользовательский идентификатор"
              label-for="include_vat"
              description="Использование в интеграции пользовательского идентификатора торгового предложения вместо системного. Изменить значение после создания интеграции будет невозможно."
            >
              <b-form-checkbox
                id="use_external_public_id"
                v-model="item.use_external_public_id"
                :state="get(validation, 'use_external_public_id[0]') ? false : null"
                switch
                :value="true"
                :unchecked-value="false"
              >
                <template v-if="item.use_external_public_id">
                  Вкл
                </template>
                <template v-else>
                  Выкл
                </template>
              </b-form-checkbox>
              <small
                v-if="!!get(validation, 'use_external_public_id[0]')"
                class="text-danger"
              >
                {{ get(validation, 'use_external_public_id[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Тип доставки *"
              label-for="delivery_type"
            >
              <b-form-input
                id="delivery_type"
                v-model.trim="item.delivery_type"
                placeholder="Выберите тип доставки"
                readonly
                :state="get(validation, 'delivery_type[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'delivery_type[0]')"
                class="text-danger"
              >
                {{ get(validation, 'delivery_type[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Название доставки *"
              label-for="delivery_name"
            >
              <b-form-input
                id="delivery_name"
                v-model.trim="item.delivery_name"
                placeholder="Выберите название доставки"
                :state="get(validation, 'delivery_name[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'delivery_name[0]')"
                class="text-danger"
              >
                {{ get(validation, 'delivery_name[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="ИНН продавца *"
              label-for="seller_inn"
            >
              <b-form-input
                id="delivery_name"
                v-model.trim="item.seller_inn"
                placeholder="Выберите ИНН продавца"
                :state="get(validation, 'seller_inn[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'seller_inn[0]')"
                class="text-danger"
              >
                {{ get(validation, 'seller_inn[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Час разделитель"
              label-for="delivery_separator_hour"
            >
              <v-select
                id="select_delivery_separator_hour"
                v-model.trim="item.delivery_separator_hour"
                placeholder="Выберите час"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="hoursOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="update_offers_price_hour"
                label="label"
                :class="{
                  'is-invalid': !!get(validation, 'delivery_separator_hour[0]')
                }"
              />
              <small
                v-if="!!get(validation, 'delivery_separator_hour[0]')"
                class="text-danger"
              >
                {{ get(validation, 'delivery_separator_hour[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Дней до разделителя"
              label-for="delivery_days_before_separator_hour"
              description="Количество дней, через которое заказ будет доставлен, если он оформлен до времени разделителя."
            >
              <b-form-input
                id="delivery_days_before_separator_hour"
                v-model.trim="item.delivery_days_before_separator_hour"
                type="number"
                placeholder="Выберите название доставки"
                :state="get(validation, 'delivery_days_before_separator_hour[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'delivery_days_before_separator_hour[0]')"
                class="text-danger"
              >
                {{ get(validation, 'delivery_days_before_separator_hour[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Дней после разделителя"
              label-for="delivery_days_after_separator_hour"
              description="Количество дней, через которое заказ будет доставлен, если он оформлен после времени разделителя."
            >
              <b-form-input
                id="delivery_days_after_separator_hour"
                v-model.trim="item.delivery_days_after_separator_hour"
                type="number"
                placeholder="Выберите название доставки"
                :state="get(validation, 'delivery_days_after_separator_hour[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'delivery_days_after_separator_hour[0]')"
                class="text-danger"
              >
                {{ get(validation, 'delivery_days_after_separator_hour[0]') }}
              </small>
            </b-form-group>
          </b-col>

        </b-row>

        <b-row class="mt-1">
          <b-col
            cols="12"
          >
            <h4 class="mb-1">
              Правила выгрузки
            </h4>
          </b-col>
          <b-col
            cols="12"
          >
            <remain-prices-rules
              :price-rules.sync="item.remain_by_price_rules"
            />
            <remain-margin-rules
              :margin-rules.sync="item.remain_by_margin_rules"
            />
            <dimensions-rules
              :dimensions-rules.sync="item.dimensions_rules"
            />

          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col>
            <b-button
              variant="primary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="createItem"
            >
              Создать
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card>
      <FAQInfo />
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BButton, BOverlay, BFormCheckbox,
} from 'bootstrap-vue'

import get from 'lodash/get'
import { getCurrentInstance, ref } from 'vue'
import { createYandexMarketDbsIntegration } from '@/services/main-api/integrations/yandex-market-dbs'
import useCrudCreate from '@/composables/useCrudCreate'
import RemainPricesRules from '@/modules/integrations/components/RemainPricesRules.vue'
import RemainMarginRules from '@/modules/integrations/components/RemainMarginRules.vue'
import DimensionsRules from '@/modules/integrations/components/DimensionsRules.vue'
import vSelect from 'vue-select'
import FAQInfo from '../FAQInfo.vue'

export default {
  name: 'CreateFormYMDBS',

  components: {
    vSelect,
    RemainPricesRules,
    RemainMarginRules,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    BFormCheckbox,
    FAQInfo,
    DimensionsRules,
  },
  setup() {
    const instance = getCurrentInstance().proxy

    const resAfterCreateEntity = ref(null)

    const hoursOptions = []

    for (let i = 0; i < 24; i += 1) {
      hoursOptions.push({
        label: `${i}`,
        value: i,
      })
    }

    const createEntity = async () => {
      resAfterCreateEntity.value = await createYandexMarketDbsIntegration({
        project_id: instance.$store.getters['workingMode/selected_project_id'],
        name: instance.item.name,
        client_id: instance.item.client_id,
        client_secret: instance.item.client_secret,
        business_id: instance.item.business_id,
        campaign: instance.item.campaign,
        campaign_token: instance.item.campaign_token,
        warehouse_id: instance.item.warehouse_id,
        include_vat: instance.item.include_vat,
        remain_by_price_rules: instance.item.remain_by_price_rules,
        remain_by_margin_rules: instance.item.remain_by_margin_rules,
        dimensions_rules: instance.item.dimensions_rules,
        use_external_public_id: instance.item.use_external_public_id,
        delivery_type: instance.item.delivery_type,
        delivery_name: instance.item.delivery_name,
        delivery_separator_hour: instance.item.delivery_separator_hour,
        delivery_days_before_separator_hour: instance.item.delivery_days_before_separator_hour,
        delivery_days_after_separator_hour: instance.item.delivery_days_after_separator_hour,
        seller_inn: instance.item.seller_inn,
      })
    }

    const {
      item,
      loading,
      validation,
      error_message,
      checkValidation,
      clearForm,
      catchValidation,
      createItem,
    } = useCrudCreate({
      item: {
        id: null,
        name: '',
        client_id: '',
        client_secret: '',
        business_id: '', // Идентификатор бизнес-аккаунта
        campaign: '',
        campaign_token: '',
        warehouse_id: '',
        include_vat: false,
        remain_by_price_rules: [],
        remain_by_margin_rules: [],
        dimensions_rules: [],
        use_external_public_id: false,
        delivery_type: 'DELIVERY',
        delivery_name: '',
        delivery_separator_hour: 0,
        delivery_days_before_separator_hour: 0,
        delivery_days_after_separator_hour: 0,
        seller_inn: '',
      },
      validation: {
        rules: {
          name: 'required',
          client_id: 'required',
          client_secret: 'required',
          business_id: 'required',
          campaign: 'required',
          campaign_token: 'required',
          warehouse_id: 'required',
          delivery_type: 'required',
          delivery_name: 'required',
          delivery_separator_hour: 'required',
          delivery_days_before_separator_hour: 'required',
          delivery_days_after_separator_hour: 'required',
          seller_inn: 'required',
        },
        customErrorMessages: {
          'required.name': 'Укажите название',
          'required.client_id': 'Укажите ClientID',
          'required.client_secret': 'Укажите Client secret',
          'required.business_id': 'Укажите идентификатор бизнес-аккаунта',
          'required.campaign': 'Укажите идентификатор кампании',
          'required.campaign_token': 'Укажите авторизационный токен кампании',
          'required.warehouse_id,': 'Укажите идентификатор склада кампании',
          'required.delivery_type': 'Укажите тип доставки',
          'required.delivery_name': 'Укажите название доставки',
          'required.delivery_separator_hour': 'Укажите час разделителя',
          'required.delivery_days_before_separator_hour': 'Укажите количество дней',
          'required.delivery_days_after_separator_hour': 'Укажите количество дней',
          'required.seller_inn': 'Укажите ИНН продавца',
        },
      },
      createEntity,
      getTextAfterCreation: () => 'Интеграция была создана.',
      runAfterCreation: async () => {
        await instance.$router.push({
          name: 'integrations.show',
          params: {
            id: resAfterCreateEntity.value.data.data.id,
            type: 'yandex_market_dbs',
          },
        })
      },
    })

    return {
      item,
      loading,
      validation,
      error_message,

      checkValidation,
      clearForm,
      catchValidation,
      createItem,
      get,

      hoursOptions,
    }
  },
}
</script>
